import axios from '../utils/axios';

export function updateSite(forms) {
    const data = {
        'name': forms.name,
        'type_name': forms.type_name,
        'contact_username': forms.contact_username,
        'contact_phone': forms.contact_phone,
        'lng': forms.lng,
        'lat': forms.lat,
        'address': forms.address,
    }
    return axios.post('/site/update', data)
}

export function querySiteInfo() {
    return axios.post('/site/query')
}