<template>
  <div class="site">
    <a-form :form="form" @submit="handleSubmit">
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="站点名称"
      >
        <a-input
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: '站点名称必填' }],
              initialValue: this.data.name,
            },
          ]"
          placeholder="站点名称"
        />
      </a-form-item>
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="站点类型"
      >
        <a-input
          v-decorator="[
            'type_name',
            {
              rules: [{ required: true, message: '站点类型必填' }],
              initialValue: this.data.type_name,
            },
          ]"
          placeholder="站点类型"
        />
      </a-form-item>
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="联系人/负责人"
      >
        <a-input
          v-decorator="[
            'contact_username',
            {
              rules: [{ required: true, message: '不能为空' }],
              initialValue: this.data.contact_username,
            },
          ]"
          placeholder="联系人/负责人姓名"
        />
      </a-form-item>

      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="联系人电话/手机"
      >
        <a-input
          v-decorator="[
            'contact_phone',
            {
              rules: [{ required: true, message: '不能为空' }],
              initialValue: this.data.contact_phone,
            },
          ]"
          placeholder="电话/手机号码"
        />
      </a-form-item>

      <!-- <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="地理位置"
      >
        <a-input placeholder="经纬度坐标" v-model="axis" disabled />
      </a-form-item> -->

      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="详细地址"
      >
        <a-input
          placeholder="详细地址"
          v-decorator="['address', { initialValue: this.data.address }]"
        />
      </a-form-item>

      <a-form-item
        :label-col="formTailLayout.labelCol"
        :wrapper-col="formTailLayout.wrapperCol"
      >
        <a-button block size="large" type="primary" html-type="submit"
          >提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { updateSite, querySiteInfo } from "@/api/site";
import { message } from "ant-design-vue";

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18, offset: 4 },
};
export default {
  name: "Site",
  data() {
    return {
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      axis: "",
      data: {},
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          // 新增站点

          updateSite(values).then((res) => {
            if (res.code === 200) {
              message.success("站点信息更新成功");
              this.loadData();
            }
          });
        }
      });
    },
    loadData() {
      querySiteInfo().then((res) => {
        localStorage.setItem("site_name", res.data.name);
        localStorage.setItem("contact_username", res.data.contact_username);
        this.data = res.data;
        if (res.data.lng && res.data.lat) {
          this.axis = "经度: " + res.data.lng + " , 纬度: " + res.data.lat;
        }
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.site {
  margin-top: 50px;
}
</style>