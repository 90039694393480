var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"站点名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: '站点名称必填' }],
            initialValue: this.data.name,
          } ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: '站点名称必填' }],\n            initialValue: this.data.name,\n          },\n        ]"}],attrs:{"placeholder":"站点名称"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"站点类型"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'type_name',
          {
            rules: [{ required: true, message: '站点类型必填' }],
            initialValue: this.data.type_name,
          } ]),expression:"[\n          'type_name',\n          {\n            rules: [{ required: true, message: '站点类型必填' }],\n            initialValue: this.data.type_name,\n          },\n        ]"}],attrs:{"placeholder":"站点类型"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"联系人/负责人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contact_username',
          {
            rules: [{ required: true, message: '不能为空' }],
            initialValue: this.data.contact_username,
          } ]),expression:"[\n          'contact_username',\n          {\n            rules: [{ required: true, message: '不能为空' }],\n            initialValue: this.data.contact_username,\n          },\n        ]"}],attrs:{"placeholder":"联系人/负责人姓名"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"联系人电话/手机"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contact_phone',
          {
            rules: [{ required: true, message: '不能为空' }],
            initialValue: this.data.contact_phone,
          } ]),expression:"[\n          'contact_phone',\n          {\n            rules: [{ required: true, message: '不能为空' }],\n            initialValue: this.data.contact_phone,\n          },\n        ]"}],attrs:{"placeholder":"电话/手机号码"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"详细地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address', { initialValue: this.data.address }]),expression:"['address', { initialValue: this.data.address }]"}],attrs:{"placeholder":"详细地址"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formTailLayout.labelCol,"wrapper-col":_vm.formTailLayout.wrapperCol}},[_c('a-button',{attrs:{"block":"","size":"large","type":"primary","html-type":"submit"}},[_vm._v("提交 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }